<template>
  <div class="home">

    <div class="right-two-thirds">
      <transition name="image-fade"
                  mode="out-in">
        <div v-show="showImage"
             key="about-image"
             class="box">

          <div>
            <h1>安装</h1>

            <h4>请使用对应浏览器打开以下链接进行安装</h4>
            <div class="button-container">

              <div v-for="item in list"
                   :key="item.name"
                   class="button-wrapper">

                <transition name="button-fade"
                            mode="out-in">
                  <div class="buttom"
                       v-show="showText"
                       @click="click(item)">
                    <img :src="item.icon"
                         alt="">
                    <span>{{item.name}}</span>
                  </div>
                </transition>
                <!-- && item.show -->
                <div class="btn_download"
                     v-if="item.type === 'download'&& item.show  "
                     @click="click2(item)">
                  <p>下载</p>
                </div>

              </div>
            </div>
            <p @click="$router.push('/installationGuide')"
               style="cursor: pointer;font-size: 15px;">安装指南</p>
            <img src="https://xgfile-web.oss-cn-beijing.aliyuncs.com/img/home.png"
                 class="image-border" />

          </div>
        </div>

      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'download',
  data () {
    return {

      showText: false,
      showImage: false,

      list: [

        {
          name: "Edge版",
          url: "https://microsoftedge.microsoft.com/addons/detail/gfechfioaanebemclajhfgkfaopcaibo",
          icon: require('@/assets/img/edge.svg')
        },
        {
          name: "Chrome版",
          url: "https://chromewebstore.google.com/detail/kolgdodmgnnhnijmnnidfabnghgakobl",
          icon: require('@/assets/img/chrome.svg')
        },
        {
          name: "360极速浏览器X版",
          url: "https://ext.se.360.cn/#/extension-detail?id=kolgdodmgnnhnijmnnidfabnghgakobl",
          icon: require('@/assets/img/360.png')
        },
        {
          name: "网页版",
          url: "https://go.xtab.link/",
          icon: require('@/assets/logo.png')
        },
        {
          name: "win桌面版",
          url: "http://download.xtab.link/download/XTab_windows1.0.exe",
          icon: require('@/assets/img/win.png'),
          type: 'download',
          show: false,
        },
        {
          name: "crx离线版",
          url: "http://download.xtab.link/download/xtab_offline.zip",
          icon: require('@/assets/img/crx.png'),
          type: 'download',
          show: false,
        },
        {
          name: "一键安装助手",
          url: "http://download.xtab.link/download/XTab.exe",
          icon: require('@/assets/InstallationGuide/icon/exe.png'),
          type: 'download',
          show: false,
        },
      ],
    };
  },
  watch: {
    '$route' () {
      this.triggerAnimation();
    }
  },
  created () {

    // // 定义 API 端点 URL
    // const apiUrl = 'https://api.xtab.link/api/public/DataReceive/downloadList';

    // // 发送 GET 请求
    // fetch(apiUrl)
    //   .then(response => {
    //     // 检查请求是否成功 (HTTP 状态码为 200 到 299 表示成功)
    //     if (!response.ok) {
    //       throw new Error('Network response was not ok');
    //     }
    //     // 将响应数据解析为 JSON 格式
    //     return response.json();
    //   })
    //   .then(data => {
    //     // 在控制台输出返回的下载列表数据
    //     // console.log('下载列表数据:', data);
    //     // 这里可以根据需要进一步处理获取到的数据，例如渲染到页面上

    //     let list = data.data
    //     // 转换为数组
    //     const dataArray = Object.keys(list).map(key => ({

    //       name: list[key].name,
    //       url: list[key].url,
    //       icon: list[key].icon
    //     }));
    //     console.log(dataArray);
    //     if (dataArray.length > 0) {
    //       this.list = dataArray
    //     }


    //   })
    //   .catch(error => {
    //     // 捕获和处理错误
    //     console.error('发生错误:', error);
    //   });


  },
  mounted () {
    this.triggerAnimation();
  },
  methods: {
    click (item) {
      this.list.forEach(element => {
        if (element.type === 'download') {
          element.show = false;
        }
      });
      if (item.type === 'download') {
        item.show = true;
        return
      }
      window.open(item.url, '_blank');
    },

    click2 (item) {
      window.open(item.url, '_blank');
    },
    triggerAnimation () {
      this.showText = false;
      this.showImage = false;
      setTimeout(() => {
        this.showText = true;
        this.showImage = true;
      }, 100);
    }
  }
};
</script>

<style scoped>
@media (max-width: 768px) {
  .home {
    height: 100vh;
    background-image: url('https://xgfile-web.oss-cn-beijing.aliyuncs.com/img/bg3.jpg');

    background-size: cover;
  }

  .right-two-thirds {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 100px;
  }

  .button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* 按钮居中显示 */
  }

  .button-wrapper {
    flex: 0 0 auto; /* Prevent items from growing */
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
  }
  .btn_download {
    position: absolute;
    background: #fff3;
    width: 114px;
    border-radius: 20px;
    margin-top: 5px;
    cursor: pointer;
  }
  .btn_download p {
    text-align: center;
    line-height: 6px;
  }
  .box {
    color: white;
  }
  .buttom {
    background: #fff3;
    border-radius: 20px;
    height: 30px;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border: 1px solid #cccccc00;
    cursor: pointer;
  }

  .buttom:hover {
    background: #409eff;
    box-shadow: 0 0 20px #409eff; /* 添加阴影效果 */
  }

  .image-border {
    /* margin-top: 50px; */
    width: 90%;
    height: auto;
    transition: opacity 1s ease-in-out;
    border-radius: 20px;
  }

  .buttom img {
    width: 22px; /* Adjust icon size */
    margin-right: 10px; /* Adjust spacing between icon and text */
  }

  .buttom span {
    font-size: 14px; /* Adjust text size */
    white-space: nowrap; /* Prevent text from wrapping */
  }

  .text-slide-enter-active,
  .text-slide-leave-active {
    transition: transform 0.5s;
  }

  .text-slide-enter,
  .text-slide-leave-to {
    transform: translateX(50%);
  }

  .text-slide-leave-active {
    transform: translateX(-100%);
  }

  .image-fade-enter-active,
  .image-fade-leave-active {
    transition: opacity 0.5s ease-in-out;
  }

  .image-fade-enter,
  .image-fade-leave-to {
    opacity: 0;
  }

  .image-fade-enter-to {
    opacity: 1;
  }
}
@media (min-width: 769px) {
  .home {
    display: flex;
    height: 100vh;
    background-image: url('https://xgfile-web.oss-cn-beijing.aliyuncs.com/img/bg3.jpg');
    background-size: cover;
  }

  .right-two-thirds {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* 按钮居中显示 */
  }

  .button-wrapper {
    flex: 0 0 auto; /* Prevent items from growing */
    margin-right: 10px; /* Adjust margin as needed */
    position: relative;
  }

  .btn_download {
    position: absolute;
    background: #fff3;
    width: 114px;
    border-radius: 20px;
    margin-top: 5px;
    cursor: pointer;
  }
  .btn_download p {
    text-align: center;
    line-height: 6px;
  }
  .box {
    color: white;
  }
  .buttom {
    background: #fff3;
    border-radius: 20px;
    height: 30px;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border: 1px solid #cccccc00;
    cursor: pointer;
  }

  .buttom:hover {
    background: #409eff;
    box-shadow: 0 0 20px #409eff; /* 添加阴影效果 */
  }

  .image-border {
    /* position: absolute; */

    /* margin-top: 50px; */
    width: 720px;
    height: auto;
    transition: opacity 1s ease-in-out;
    border-radius: 20px;
  }

  .buttom img {
    width: 22px; /* Adjust icon size */
    margin-right: 10px; /* Adjust spacing between icon and text */
  }

  .buttom span {
    font-size: 14px; /* Adjust text size */
    white-space: nowrap; /* Prevent text from wrapping */
  }

  .text-slide-enter-active,
  .text-slide-leave-active {
    transition: transform 0.5s;
  }

  .text-slide-enter,
  .text-slide-leave-to {
    transform: translateX(50%);
  }

  .text-slide-leave-active {
    transform: translateX(-100%);
  }

  .image-fade-enter-active,
  .image-fade-leave-active {
    transition: opacity 0.5s ease-in-out;
  }

  .image-fade-enter,
  .image-fade-leave-to {
    opacity: 0;
  }

  .image-fade-enter-to {
    opacity: 1;
  }
}

.button-fade-enter-active,
.button-fade-leave-active {
  transition: transform 0.5s;
}

.button-fade-enter,
.button-fade-leave-to {
  transform: translateY(-50%);
}

.button-fades-enter-to {
  transform: translateY(-100%);
}
</style>
